import SvgIcon from "@material-ui/core/SvgIcon";
import React from "react";

export { default as AddCardIcon } from "@material-ui/icons/AddCircleOutline";
export { default as CloseIcon } from "@material-ui/icons/Close";
export { default as DownloadIcon } from "@material-ui/icons/CloudDownloadOutlined";
export { default as DashboardIcon } from "@material-ui/icons/Dashboard";
export { default as LogoutIcon } from "@material-ui/icons/ExitToApp";
export { default as GitHubIcon } from "@material-ui/icons/GitHub";
export { default as LatestIcon } from "@material-ui/icons/NewReleases";
export { default as CreateAdapterIcon } from "@material-ui/icons/LibraryAdd";
export { default as DiscoveryIcon } from "@material-ui/icons/SettingsInputAntenna";
export { default as AdapterCheckIcon } from "@material-ui/icons/Spellcheck";

/**
 * Icon based on Nijel at English Wikipedia, CC BY-SA 3.0 <https://creativecommons.org/licenses/by-sa/3.0>, via Wikimedia Commons
 * https://commons.wikimedia.org/wiki/File:Weblate_logo.svg
 */
export function WeblateIcon(props: any) {
	return (
		<SvgIcon {...props} viewBox="50 50 200 200">
			<g
				style={{ fill: "none", fillRule: "evenodd" }}
				id="g27"
				transform="translate(50,76)"
			>
				<path
					style={{ fill: "currentColor", fillRule: "nonzero" }}
					id="path21"
					d="m 127.24725,111.60641 c -2.88836,-0.0145 -5.76656,-0.6024 -8.47973,-1.78467 -6.11167,-2.66259 -11.49342,-7.69116 -15.87156,-14.494798 1.24856,-2.219307 2.3738,-4.517338 3.37843,-6.853465 4.40506,-10.243222 6.50003,-21.459724 6.6607,-32.59332 -0.0233,-0.220825 -0.0416,-0.442436 -0.0552,-0.664833 l -0.0121,-0.571316 c -0.01,-4.365422 -0.67459,-8.789784 -2.17667,-12.908841 -1.73043,-4.745776 -4.4887,-9.495481 -8.86498,-11.347741 -0.79519,-0.335952 -1.63162,-0.477013 -2.464161,-0.457367 -5.504872,-10.288802 -5.679939,-20.1485266 0,-29.53713169 0.10115,0 0.20619,3.9293e-4 0.30734,0.001179 6.701161,0.0738703 13.340071,2.14184679 19.021181,5.75363459 15.46928,9.8349701 23.18193,29.0007861 23.35194,47.8180741 0.002,0.220826 -3.9e-4,0.441258 -0.007,0.66169 h 0.0868 c -0.0226,19.886801 -4.80489,40.053612 -14.87495,56.978905 z m -34.299625,31.21585 c -14.447871,5.94246 -31.22819,5.62359 -45.54931,-1.02501 C 30.922601,134.1497 18.333366,119.28527 10.57986,102.31827 -2.6824097,73.29666 -2.9858585,38.603143 9.5917057,9.1367387 19.037523,12.915521 27.43683,6.8970531 27.43683,6.8970531 c 0,0 -0.01945,9.2605109 8.94785,13.9049119 -9.200724,21.556385 -8.978973,47.166601 0.241203,68.172888 4.438911,10.107269 11.219825,19.519057 20.618957,24.841647 3.354732,1.8996 7.040998,3.12602 10.833017,3.58618 0.01404,0.0219 0.02808,0.0439 0.04214,0.0658 6.696522,10.44898 15.132208,19.1568 24.827633,25.35378 z"
				/>
				<path
					style={{ opacity: 0.3, fill: "currentColor" }}
					id="path23"
					d="m 127.24298,111.61346 c -2.88693,-0.0151 -5.76363,-0.60296 -8.47546,-1.78465 -6.11266,-2.66302 -11.49516,-7.69279 -15.87368,-14.498094 1.24941,-2.220476 2.37535,-4.519788 3.38055,-6.857239 1.32817,-3.088408 2.44626,-6.264833 3.36439,-9.500982 2.12803,-7.497839 30.38184,2.018075 26.0717,14.370923 -2.22386,6.372982 -5.03943,12.509222 -8.4675,18.270042 z m -34.302088,31.21157 c -14.446351,5.93959 -31.223682,5.61979 -45.542576,-1.02778 -16.475715,-7.64755 0.447392,-33.30334 9.846524,-27.98075 3.353286,1.89878 7.037819,3.12496 10.828112,3.58558 0.01567,0.0245 0.03135,0.049 0.04704,0.0735 6.694972,10.44656 15.128303,19.15278 24.8209,25.34948 z"
				/>
				<path
					style={{ fill: "currentColor", fillRule: "nonzero" }}
					id="path25"
					d="m 56.762435,54.627505 c -0.0066,-0.220432 -0.0093,-0.440865 -0.007,-0.66169 C 56.925444,35.148526 64.638101,15.982711 80.107377,6.1477407 85.788484,2.5359528 92.427399,0.46797642 99.12856,0.39410609 c 0.10115,-7.8585e-4 0.20619,-0.001179 0.307339,-0.001179 V 29.930059 c -0.832539,-0.01965 -1.668968,0.121414 -2.46416,0.457367 -4.376276,1.85226 -7.134548,6.601965 -8.864984,11.347741 -1.502072,4.119057 -2.166936,8.543418 -2.176662,12.908841 l -0.01206,0.571316 c -0.01362,0.222397 -0.0319,0.444008 -0.05524,0.664833 0.160672,11.133595 2.255636,22.350098 6.660701,32.59332 4.933377,11.471906 12.775196,22.025153 23.847186,26.848723 8.35263,3.63969 17.61171,2.78114 25.18236,-1.5057 9.39914,-5.32259 16.18005,-14.734378 20.61896,-24.841647 9.22018,-21.006287 9.44193,-46.616503 0.24121,-68.172888 8.9673,-4.644401 8.94785,-13.9049119 8.94785,-13.9049119 0,0 8.3993,6.0184679 17.84512,2.2396856 12.57756,29.4664043 12.27412,64.1599213 -0.98815,93.1815313 -7.75351,16.967 -20.34275,31.83144 -36.81846,39.47898 -14.66669,6.80904 -31.9127,6.97917 -46.59106,0.58389 C 91.618199,136.63222 80.890116,126.27466 73.171624,114.23104 61.993036,96.788212 56.699411,75.552849 56.675679,54.627505 Z"
				/>
			</g>
		</SvgIcon>
	);
}

/**
 * Icon based on https://seeklogo.com/vector-logo/394621/sentry
 */
export function SentryIcon(props: any) {
	return (
		<SvgIcon {...props} viewBox="0 0 256 256">
			<g>
				<path
					d="M148.367708,12.4025287 C144.036807,5.21480737 136.258026,0.820118864 127.866362,0.820118864 C119.474697,0.820118864 111.695916,5.21480737 107.365016,12.4025287 L73.6403017,70.165071 C126.066153,96.3390588 160.689085,148.341727 164.615024,206.806542 L140.93597,206.806542 C137.017513,156.694333 106.874845,112.396698 61.6982677,90.3588968 L30.4849684,144.32869 C55.8497707,155.704426 73.6778379,179.211206 77.7918243,206.704035 L23.4120041,206.704035 C22.1018479,206.611361 20.9266153,205.864669 20.2861278,204.71799 C19.6456403,203.571311 19.6261529,202.179068 20.2342955,201.014912 L35.3027847,175.388229 C30.1976229,171.128798 24.3630321,167.829476 18.0816541,165.65009 L3.16692493,191.276772 C0.0305635285,196.656417 -0.818661742,203.068719 0.809210488,209.079324 C2.43708272,215.08993 6.40620885,220.197261 11.8287436,223.258872 C15.3657216,225.251729 19.3523095,226.310116 23.4120041,226.334074 L97.8831433,226.334074 C100.696274,191.620878 85.1423372,157.966047 56.8804514,137.614499 L68.7199787,117.113153 C104.398813,141.618242 124.473737,183.151896 121.510945,226.334074 L184.603837,226.334074 C187.593899,160.904124 155.557278,98.8221906 100.497065,63.3483734 L124.432386,22.3456815 C125.542508,20.4856859 127.944329,19.8680747 129.81399,20.9618406 C132.530418,22.4481882 233.807067,199.169791 235.703442,201.219925 C236.383476,202.439289 236.358897,203.929352 235.639016,205.125624 C234.919136,206.321896 233.614065,207.041397 232.218213,207.011555 L207.821611,207.011555 C208.129131,213.537817 208.129131,220.046994 207.821611,226.539592 L232.32072,226.539592 C238.604421,226.580218 244.643414,224.105731 249.091568,219.667205 C253.539722,215.228679 256.027289,209.195062 256,202.911286 C256.002825,198.802186 254.905596,194.767215 252.822066,191.225519 L148.367708,12.4025287 Z"
					style={{ fill: "currentColor" }}
				></path>
			</g>
		</SvgIcon>
	);
}

/**
 * Icon based on https://upload.wikimedia.org/wikipedia/commons/1/16/IoBrokerLogo.svg
 */
export function IoBrokerIcon(props: any) {
	return (
		<SvgIcon {...props} viewBox="0 0 500 500">
			<g transform="translate(-73.6,-73)">
				<g>
					<path
						style={{ fill: "currentColor" }}
						d="m 378,89 v 67.8 c 72.6,22.6 125.5,90.2 125.5,169.9 0,98.1 -80.2,177.9 -178.7,177.9 C 226.3,504.6 146,424.8 146,326.7 146,247 198.9,179.5 271.5,156.8 V 89 C 163.9,113.5 83.6,210.6 83.6,326.7 c 0,134.6 107.9,243.7 241.1,243.7 133.2,0 241.1,-109.1 241.1,-243.7 C 565.8,210.6 485.5,113.5 378,89 Z"
					/>
				</g>
				<path
					style={{ fill: "currentColor" }}
					d="m 324.7,148.8 c 12.4,0 24.5,1.3 36.3,3.7 V 85.8 C 349.2,84 337.1,83 324.7,83 c -12.4,0 -24.4,0.9 -36.3,2.7 v 66.7 c 11.8,-2.4 23.9,-3.6 36.3,-3.6 z"
				/>
				<g>
					<path
						style={{ fill: "currentColor" }}
						d="m 324.7,167 c -12.5,0 -24.6,1.4 -36.2,4.1 v 308.5 c 11.6,2.7 23.8,4.1 36.2,4.1 12.5,0 24.6,-1.4 36.3,-4.1 V 171.2 C 349.3,168.4 337.2,167 324.7,167 Z"
					/>
				</g>
			</g>
		</SvgIcon>
	);
}

/**
 * Icon based on https://vecta.io/symbols/89/brands-na-nz/74/npm-icon
 */
export function NpmIcon(props: any) {
	return (
		<SvgIcon {...props} viewBox="8 8 48 48">
			<g stroke="none" fillRule="nonzero">
				<path
					style={{ fill: "currentColor" }}
					d="M10.728 53.272h21.086V21.456h10.728v31.816H53.27V10.728H10.728z"
				/>
			</g>
		</SvgIcon>
	);
}
